<template>

<div class="number d-flex align-items-start counter-nw">
    <span class="minus d-flex justify-content-center align-items-center" @click="$emit('minusItem',item,index)">-</span>
    <input type="text" :value="item.quantity" readonly />
    <span class="plus d-flex justify-content-center align-items-center" @click="$emit('plusItem',item,index)">+</span>
</div>

</template>

<script>
export default {
    props: ['item', 'index']
}
</script>

<style>

</style>