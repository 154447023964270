<template>
  <div class="number d-flex align-items-center ">
    <small class="font-size-16">
      <slot></slot>
    </small>
  </div>
</template>

<script>
export default {};
</script>