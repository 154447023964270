<template>
<div class="login-main">
    <nav-bar v-on:sendDefaultAddress="getAddress" :checkpage="page"></nav-bar>
    <section class="review-order-start">
        <div class="review-container review-main text-capitalize position-relative review-container-nw">
            <div class="row" v-if="cartItemCount > 0">
                <div class="col-md-12 p-0">
                    <div class="resturant-details d-flex align-items-start plr15">
                        <div class="rst-nme-add">
                            <h4 class="mt-0">{{$t('Your Cart')}} <span class="total-items">({{cart.reduce((a, c) => (a + c.quantity),0)}} {{cart.reduce((a, c) => (a + c.quantity),0) > 1 ? $t('Items'):$t('Item')}})</span></h4>
                            <p class="frm-txt">{{$t('From')}} :
                                <a href="javascript:void(0)" v-if="!restaurantData.slug">
                                    <skeleton-loader-vue type="string" width="50%" height="20px" />
                                </a>
                                <router-link v-else :to="{name:'vendor', params:{restaurant_name: restaurantData.slug}}" class="resst-lnk">{{ this.restaurantData.name }}</router-link>
                            </p>
                        </div>
                    </div>
                    <div class="order-items-main plr15">
                        <div class="order-item d-flex align-items-center justify-content-between" v-for="(item, index) in this.cart" :key="index">                         
                            <div class="item-heading-main">
                                <!-- <div class="crt-img crt-img-nw" :class="(item.image == '' ||  item.image == null) ?'noimg':''">
                                    <img v-if="item.image" :src="item.image">
                                    <div class="veg veg-non-nw" v-if="item.item_type == '0'"><span></span></div>
                                    <div class="non-veg veg-non-nw" v-if="item.item_type == '1'"><span></span></div>
                                </div> -->
                                <div>
                                    <span class="cust-remove" @click="deleteProduct(index)">&times;</span>
                                </div>
                                <div class="crt-img crt-img-nw cust-img" :class="item.image == '' || item.image == null ? 'noimg' : '' ">
                                    <img v-if="item.image" :src="item.image" @error="$event.target.src = noImge" />
                                    <div class="veg veg-non-nw" v-if="item.item_type == '0'"><span></span></div>
                                    <div class="non-veg veg-non-nw" v-if="item.item_type == '1'"><img src="@/assets/images/non_veg.png" /></div>
                                </div>
                                
                                <div class="item-heading item-heading-nw">
                                    <!--<div class="veg" v-if="item.item_type == '0'"><span></span></div><div class="non-veg" v-if="item.item_type == '1'"><span></span></div>-->
                                    <p class="mb-0">{{ item.name }} : </p>
                                    <p class="mb-0" v-if="item.item_note != ''">{{truncate(item.item_note,20, '...')}}</p>
                                </div>
                            </div>

                            <div class="d-flex align-items-center  text-right justify-content-center mobiitem-nw">
                                <plus-minus v-if="item.price_type !== 'kg'" :item="item" :index="index" @minusItem="minusItem" @plusItem="plusItem"></plus-minus>
                                <!-- <div   class="number d-flex align-items-start counter-nw">
                                    <span class="minus d-flex justify-content-center align-items-center" @click="minusItem(item,index)">-</span>
                                    <input type="text" :value="item.quantity" readonly />
                                    <span class="plus d-flex justify-content-center align-items-center" @click="plusItem(item,index)">+</span>
                                </div> -->
                                <kg-pill class="" v-else >
                                    <a href="javascript:void(0)" class="counter-nw" @click="changeKgValue(index)">{{ parseFloat(item.item_weight).toFixed(1) +' /'+ $t('KG') }}</a>
                                </kg-pill>
                                <div class="cust_note">
                                    <a href="javascript:void(0)" class="btn btn-del" @click="addItmeNote(index)"><img src="@/assets/images/note.png"/></a>
                                </div>
                                
                                <div class="final-item-price final-item-price-nw ">
                                    <p class="mb-0"><span id="currency" v-html="$auth.setting.currency"></span>{{parseFloat(getTotalItemPrice(item)).toFixed(2)}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="plr15 review-section">
                        <div class="review-boxs mt-4">
                            <h6 class="mb-2">{{$t('special instructions')}}</h6>
                            <input class="form-control mb-0 h50" type="search" :placeholder="$t('Any Instructions related to order')" aria-label="instructions" v-model="instructions" />
                        </div>
                        <div class="review-boxs mt-4 promocode-sec">
                            <h6 class="mb-2">{{$t('promo code')}}</h6>
                            <a href="javascript:void(0)" @click="promocodeModal" class="lnk offer-btn">{{$t('View Offers')}}</a>
                            
                            <input class="form-control mb-0 h50" type="text" :placeholder="$t('Enter Promo code')" aria-label="promocode" v-model="promocode" ref="promocode" />
                            <a href="javascript:void(0)" @click="applyPromocode(promocode)" class="lnk" v-if="!isPromocodeApply">{{$t('Apply')}}</a>
                            <a href="javascript:void(0)" @click="removePromocode()" class="lnk" v-if="isPromocodeApply">{{$t('Remove')}}</a>
                        </div>
                        <p v-if="promoError" class="promoerr">{{promoError}}</p>
                        <div class="review-boxs mt-4 delivery-time">
                            <div class="d-flex justify-content-between align-items-center">
                                <h6 class="mb-0">
                                    {{(isPickupDelivery == 1 || isPickupDelivery == -1) ? $t('Delivery Time') : $t('Takeaway Time')}}
                                </h6>
                                <delivery-time v-if="restaurantId > 0" ref="DeliveryTime" :isDelivery="isPickupDelivery" :restaurantId="restaurantId" :restaurantData="restaurantData" v-on:setOrderTime="onSetOrderTime"></delivery-time>
                            </div>
                            <p class="mb-0 mt-2">
                                {{(scheduleDateTime.time && scheduleDateTime.time != '' && scheduleDateTime.time != 'asap') ? 
                                scheduleDateTime.displayText : 
                                (
                                  (isAsapEnable) ? (restaurantData && (!isRestaurantClosed || !isSameDayDelivery))?$t('Select Time Slot'): $t('As soon As possible') : 
                                  (
                                    (isPickupDelivery == 1 || isPickupDelivery == -1) ? $t('Select delivery time') : $t('Select takeaway time')
                                  )
                                )
                              }}
                            </p>
                        </div>
                    </div>
                    <div class="billing-data custom-checkbox" v-if="$auth.setting.contact_less_delivery && (isPickupDelivery == 1 || isPickupDelivery == -1) && $auth.setting.is_pickup_enable">
                        <div class="d-flex justify-content-between align-items-center form-group">
                            <h6>{{$t('Opt in for No-contact Delivery')}}</h6>
                            <input class="form-group" id="cntctdelivery" type="checkbox" true-value="1" false-value="0" v-model="contactDelivery">
                            <label class="form-check-label" for="cntctdelivery"></label>
                        </div>
                        <p class="pcolor">{{this.$auth.setting.terminology && $auth.setting.terminology[0].contact_less_delivery_text}}</p>
                    </div>
                    <div class="billing-data custom-checkbox custom-checkbox-nw" v-if="$auth.setting && $auth.setting.is_upload_order_image == 'Yes'">
                        <div class="d-flex justify-content-between align-items-center form-group">
                            <h6>{{$t($auth.setting.image_upload_text)}}</h6>
                        </div>
                        <div class="fileupload-main d-flex align-items-start">
                            <label class="filelabel"><i class="fa fa-plus"></i><input multiple accept="image/jpeg,image/png,image/jpg" @change="uploadImage" class="FileUpload1" id="FileInput" type="file" /></label>
                            <div v-if="images.length > 0" class="uploaded-img-main">
                                <div v-for="(img, key) in images" :key="key" class="prvie-img uploaded-img">
                                    <p class="remove-flg" @click="removeUploadImage(img.id)">X</p>
                                    <img :src="img.image" class="dis-img">
                                </div>
                                <div class="text-center upload-loader " v-if="loading">
                                    <b-spinner class="align-middle"></b-spinner>
                                </div>
                            </div>
                        </div>                        
                    </div>

                    <div id="billing-data" class="billing-data">
                        <h6 class="mb-3">{{$t('Billing Details')}}</h6>
                        <ul>
                            <li class="d-flex justify-content-between">
                                {{$t('SubTotal')}} 
                                <!-- <span>{{$t('(Including item')}}{{this.$auth.setting && this.$auth.setting.terminology && this.$auth.setting.terminology[0].tax_name}} <span v-html="$auth.setting.currency"></span>{{savedPrice()+')'}}</span> -->
                                <span><span v-html="$auth.setting.currency"></span>{{getTotalItemAmount()}}</span>
                            </li>
                            <li class="d-flex justify-content-between svprc" v-if="savedPrice() > 0">
                                {{$t('You Saved')}}
                                <span><span v-html="$auth.setting.currency"></span>{{savedPrice()}}</span>
                            </li>
                            <li class="d-flex justify-content-between" v-if="this.$auth.setting && (this.$auth.setting.included_tax == 0 || this.$auth.setting.included_tax == 'Yes')">
                                {{this.$auth.setting && this.$auth.setting.terminology && this.$auth.setting.terminology[0].tax_name}}({{this.gstMaxtax}}%)
                                <span><span v-html="$auth.setting.currency"></span>{{gstTotalAmount()}}</span>
                            </li>
                            <!-- <li class="d-flex justify-content-between" v-if="getDeliveryChrge() > 0">  -->
                            <li class="d-flex justify-content-between">
                                {{$t('Delivery Charges')}}
                                <span><span v-html="$auth.setting.currency"></span>{{deliveryCharge}}</span>
                            </li>
                            <li class="d-flex justify-content-between discount" v-if="promoDiscount">
                                {{$t('Discount')}}
                                <span><span v-html="$auth.setting.currency"></span>{{promoDiscount}}</span>
                            </li>
                            <li class="d-flex justify-content-between promo-discount" v-if="promoCashback">
                                {{$t('Cashback')}}
                                <span><span v-html="$auth.setting.currency"></span>{{promoCashback}}</span>
                            </li>

                            <li class="d-flex justify-content-between bold">
                                {{$t('To Pay')}}
                                <span><span v-html="$auth.setting.currency"></span>{{getTotalAmount()}}</span>
                            </li>
                        </ul>
                        <li v-if="(isPickupDelivery == 1 ||isPickupDelivery  == -1)">
                            <p class="pcolor">{{$auth.setting.terminology && $auth.setting.terminology[0].cart_info_text}}</p>
                        </li>
                    </div>

                    <div class="delivery-address plr15">
                        <div class="d-flex align-items-center justify-content-between" v-if="(isPickupDelivery == 1 || isPickupDelivery  == -1)">
                            <h6 class="mb-0">{{$t('Delivery To '+primaryAddress.adress_clarification)}}
                                <div v-if="this.address == ''" class="addaddrs">
                                    <router-link :to="{ name: 'add-address' }">{{$t('Add New Address')}}</router-link>
                                </div>
                                <div v-else class="chng-addrs">
                                    <a href="javascript:void(0)" @click="deliveryModalOpen" class="lnk">{{$t('Change Address')}}</a>
                                </div>
                            </h6>
                            <p>{{ this.address }}</p>
                        </div>
                        <div v-if="isPickupDelivery == 0" class="pckup-addr-cls">
                            <div class="pic-cls-con">
                                <h6>{{$t('You will need to take away this order from')}}</h6>
                                <p v-bind:title="restaurantData.location_address">{{ this.restaurantData.location_address }}</p>
                            </div>
                            <div class="map-add-cls">
                                <a :href="'https://www.google.com/maps?saddr='+latitude+','+longitude+'&daddr='+restaurantLat+','+restaurantLong" target="_blank">></a>
                            </div>
                        </div>
                        <div class="full-btn">
                            <a href="#billing-data" class="btn billing-btn view-dtail-cls"><span v-html="$auth.setting.currency"></span>{{getTotalAmount()}}<span>{{$t('View Bill Details')}}</span></a>
                            <a v-if="(getTotalItemAmount() < parseInt(this.restaurantData.minimum_order_value))" href="javascript:void(0);" class="prcc-cls">{{$t('Minimum Order Value')}}<span v-html="$auth.setting.currency"></span>{{this.restaurantData.minimum_order_value}}</a>
                            <b-button variant="primary" class="review-cart-btn" disabled v-else-if="is_btn_spinner">
                                <b-spinner small type="grow"></b-spinner>
                                {{$t('Loading')}}...
                            </b-button>
                            <a v-else href="javascript:void(0);" class="prcc-cls-blr" @click="processToPay()">{{$t('Proceed To Pay')}}</a>
                            <router-link ref="redirectPayment" style="display:none !important;" :to="{ name: 'payment' }">{{$t('Proceed To Pay')}}</router-link>
                        </div>
                    </div>
                </div>
                <!--------All Promocode Modal start---------->
                <div class="modal fade order-placed" id="promocodeModal" tabindex="-1" aria-labelledby="promocodeModallabel" aria-hidden="true" ref="promocodeModalRef" style="z-index: 9999;">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title" id="filterLabel">{{$t('Promocode')}}</h4>
                                <a data-dismiss="modal" class="lnk">{{$t('Close')}}</a>
                            </div>
                            <div class="modal-body text-center" v-if="promoCodes.length > 0">
                                <div class="order-item d-flex align-items-end justify-content-between" v-for="(offers, index) in this.promoCodes" :key="index">
                                    <div class="d-flex align-items-start">
                                        <img :src="offers.coupon_image" />
                                        <div class="order-data ml-3 d-flex align-items-start justify-content-between w-100">
                                            <div class="orders-status-main">
                                                <h6 class="mb-0">{{ offers.coupon_name }}</h6>
                                                <p class="mt-1">{{ offers.description }}</p>
                                                <div class="promocode mt-2">
                                                    <p>{{$t('Code')}}: {{ offers.coupon_code }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <a class="cart-btn" @click="applyPromocode(offers.coupon_code)">{{$t('Apply')}}</a>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="modal-body text-center">
                                <p>{{$t('No Offers Available')}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!--------All Promocode Modal End---------->
                <!--------Item Note Start---------->
                <div class="modal fade" id="itemNoteModal">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content h-auto w-auto">
                            <div class="modal-header">
                                <h5 class="modal-title">{{$t('Item Note')}}</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body item_note">
                                <textarea v-model="itemNote" rows="4" cols="30" autofocus ref='weightInputFocus'></textarea>                                                
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn--primary"  @click="addProductToCart(modal_id)">{{$t('Add')}}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!--------Item Note End------------>
                <!--------Change Kg Start---------->
                <div class="modal fade" tabindex="-1" role="dialog" id="itemWeightModal">
                    <div class="modal-dialog modal-sm" role="document">
                        <div class="modal-content h-auto w-auto">
                            <div class="modal-header">
                                <h5 class="modal-title">{{$t('Enter kg')}}</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body item_weight">
                                <!-- <input class="w-100" type="number" v-model="itemWeight" ref='weightInputFocus' autofocus />               -->
                                <b-form-input  id="inputText1" ref="inputText1" v-model="itemWeight" autofocus v-on:keypress="floatingNumber($event, itemWeight)"></b-form-input>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn--primary"  @click="changeProductToCart(item_index)">{{$t('Add')}}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!--------Change Kg End------------>
            </div>
            <div v-else class="order-items-main">
                <div class="full-btn">
                    <div class="crt-img-cls"><img src="@/assets/images/empty cart.png" /></div>
                    <p class="empty-cart">{{$t('Cart Empty')}}</p>
                    <a href="javascript:void(0)" @click="goToHome()">{{$t('Go Back')}}</a>
                </div>
            </div>
        </div>
    </section>    
    <Footer></Footer>
</div>
</template>

<script>
import NavBar from "../components/NavBar.vue";
import DeliveryTime from "../components/DeliveryTime.vue";
import Footer from '../components/Footer.vue';
import {
    mapGetters,
    mapActions,
    mapMutations
} from "vuex";
import moment from "moment";
import VueSkeletonLoader from 'skeleton-loader-vue';
import {KgPill, PlusMinus} from '../elements'
import { truncate } from "../helper/helper";
import noImge from "@/assets/images/place_holder.png";

export default {
    components: {
        NavBar,
        DeliveryTime,
        Footer,
        KgPill,
        PlusMinus,
        'skeleton-loader-vue': VueSkeletonLoader
    },
    data() {
        return {
            moment: moment,
            restaurantData: "",
            address: '',
            gstMaxtax: '',
            minimumOrder: '',
            cartItemCount: 1,
            promocode: '',
            promoError: '',
            promoDiscount: 0,
            isPromocodeApply: false,
            promoCashback: 0,
            promoCodeType: 0,
            // promocodeModal:false,
            contactDelivery: 0,
            place_order_parameter: [],
            shiping_id: '',
            coupon_id: '',
            instructions: '',
            restaurantId: 0,
            scheduleDateTime: {},
            promoCodes: [],
            latitude: '',
            longitude: '',
            restaurantLat: '',
            restaurantLong: '',
            page: 'review_page',
            isAsapEnable: false,
            timeFormat: 'HH:mm:ss',
            isRestaurantClosed: false,
            isSameDayDelivery: false,
            is_btn_spinner: false,
            images: [],
            orderImagesId: '',
            loading: false,
            deliveryCharge: 0,
            itemNote:'',
            modal_id : '',
            itemWeight: '',
            item_index:'',
            noImge,
        };
    },

    mounted() {
      if (this.$auth.user) {
          this.getRestaurantInfo();
          this.getAddress();
          //this.getDeliveryLocation();
          //this.getDeliveryChrge();
          let user = JSON.parse(localStorage.getItem("vuex"));
          this.token = this.$auth.user.token;
          this.userId = this.$auth.user.user_id;

          navigator.geolocation.getCurrentPosition((position) => {
              this.latitude = position.coords.latitude;
              this.longitude = position.coords.longitude;
          });
          let currentTime = moment().format(this.timeFormat);
          if (this.$auth.setting) {
              let system_start_time = (this.$auth.setting && this.$auth.setting.system_start_time != '') ? this.$auth.setting.system_start_time.split(' ').pop() : '00:00:00';
              let system_end_time = (this.$auth.setting && this.$auth.setting.system_end_time != '') ? this.$auth.setting && this.$auth.setting.system_end_time.split(' ').pop() : '23:59:00';
              this.isSameDayDelivery = (this.$auth.setting && this.$auth.setting.is_same_day_delivery == 0) ? false : true;
              let beforeTime = moment(system_start_time, this.timeFormat);
              let afterTime = moment(system_end_time, this.timeFormat);
              if (moment(currentTime, this.timeFormat).isBetween(beforeTime, afterTime)) {
                  this.isAsapEnable = true;
              } else {
                  this.isAsapEnable = false;
              }
          }
      } else {
          this.$router.push({
              name: "sign-in"
          });
      }

      if (localStorage.getItem('orderUploadImages') != '' && localStorage.getItem('orderUploadImages') != null) {
          this.images = JSON.parse(localStorage.getItem('orderUploadImages'));
      }
    },
    computed: {
        ...mapGetters("product", ["cart"]),
        ...mapGetters("restaurant", ["restaurantDetails"]),
        ...mapGetters("address", ["addressList", "primaryAddress"]),
        ...mapGetters("order", ["isPickupDelivery"]),
    },
    methods: {
        ...mapActions("product", ["removeProductFromCart"]),
        ...mapMutations("product", ["decrementProductInventary", "IncrementItemQuantity","setItemNote"]),
        ...mapActions("address", ["getPrimaryAddress"]),
        ...mapActions("restaurant", ["getRestaurantDetails", "checkVendorRadius"]),
        ...mapActions("promocode", ["promocodeRedeem", "updatePromoCode"]),
        ...mapMutations("order", ["placeOrderData", "clearPlaceOrderData"]),
        ...mapActions("order", ["postUploadOrderImages", "postremoveOrderImages","getOrderDistancekm"]),
        truncate,
        //save your item price
       savedPrice(){
        let savemrpTotal = 0;
        this.cart.forEach(item => {
            savemrpTotal += (item.saveprice * item.quantity);            
        });
        return savemrpTotal;
       }, 
      deliveryModalOpen() {
        $('.location-modal').addClass('heightModal');
        $('#deliveryModal').modal('show');
      },

      //Promocode modal
      promocodeModal() {
        let deliveryType = 'Delivery';
        if (this.isPickupDelivery == 1 || this.isPickupDelivery == -1) {
            deliveryType = 'Delivery';
        } else {
            deliveryType = 'Pickup';
        }
        this.updatePromoCode({
            vendor_id: this.$auth.getVendorId(),
            coupon_type: deliveryType
        }).then((data) => {
            if (data.code == 200) {
                this.promoCodes = data.Result;
            }
            $('#promocodeModal').modal('show');
        });
      },

      onSetOrderTime(value) {
          this.scheduleDateTime = value;
      },

      //Get address for API call
      getAddress() {
        this.getPrimaryAddress({}).then((data) => {
          if (data.code == 200) {
              this.getDeliveryChrge();
              this.address = data.Result.flat_no + " " + data.Result.house_name + " " + data.Result.Shiping_address;
              this.shiping_id = data.Result.shiping_id;
          }
          if (data.code == 101) {
              this.address = '';
          }
          if (data.code == 401) {
              this.address = '';
          }
        });
      },

      //Get Restaurant Information
      getRestaurantInfo() {
        if (this.cart.length) {
          this.restaurantId = this.cart[0].restaurant_id;
          this.getRestaurantDetails({
              vendor_id: this.$auth.getVendorId(),
              restaurant_id: this.restaurantId,
              is_langauge: this.$store.state.lang.locale,
          }).then((data) => {
              if (data.code == 200) {
                  this.restaurantData = data.Result;
                  this.restaurant_service_tax = data.Result.restaurant_service_tax;
                  this.restaurantLat = data.Result.latitude;
                  this.restaurantLong = data.Result.longitude;
                  this.isRestaurantClosed = (data.Result.restaurant_on_off == '0' ? false : true);
                  this.getDeliveryChrge();
              }
          });
        }
      },

      //Display Total Amount Of item cart
      getTotalItemPrice(item) {
        if(item.price_type === 'kg'){
            return item.price * item.item_weight;
        }
        return item.price * item.quantity;
      },

      //Get Total Amount of Items
      getTotalItemAmount() {
        let itmTotal = 0;
        let itemCount = 0;
        this.cart.forEach(item => {
            itmTotal += this.getTotalItemPrice(item);
            itemCount++;
        });
        this.cartItemCount = itemCount;
        return parseFloat(itmTotal).toFixed(2);
      },

      //Increament Item
      plusItem(item, index) {
        if (item.itemTotalQuantity > item.quantity) {
          this.$store.commit("product/IncrementItemQuantity", index);
          this.getDeliveryChrge();
        }
      },

      //Decrement Item
      minusItem(cartProduct, index) {
          this.removePromocode();
          if (cartProduct.quantity == '1' || cartProduct.quantity == '0') {
              this.deleteProduct(index);
          } else {
              this.$store.commit("product/decrementProductInventary", index);
              this.getDeliveryChrge();
          }
      },

      //Remove Item from cart
      deleteProduct: function (index) {
          this.$store.commit("order/clearPlaceOrderData");
          this.$store.dispatch("product/removeProductFromCart", index)
      },

      //GST Total Amount Display
      gstTotalAmount() {
        if (this.$auth.setting && (this.$auth.setting.included_tax == 0 || this.$auth.setting.included_tax == 'Yes')) {
            let gstServiceTax = parseFloat(this.restaurantDetails.restaurant_service_tax);
            this.gstMaxtax = gstServiceTax;
            return ((this.getTotalItemAmount() / 100) * gstServiceTax).toFixed(2);
        } else {
            return 0;
        }
      },


      //Delivery Charge Total Amount Display
      getDeliveryChrge() {
        let vardeliveryCharge = 0;
        if (this.isPickupDelivery == 1 || this.isPickupDelivery == -1) {
          if (this.$auth.setting && this.$auth.setting.delivery_charges_type == "Distance") {
              let unit = "K";
              if (this.$auth.setting && this.$auth.setting.distance_metric == "Miles") {
                  unit = "N";
              }
              let clntLatlng = this.primaryAddress;
              let restLatlng = this.restaurantDetails;              
              if (clntLatlng.latitude != undefined && clntLatlng.longitude != undefined && restLatlng.latitude != undefined && restLatlng.longitude != undefined) {
                this.getDeliveryLocation(clntLatlng.latitude, clntLatlng.longitude, restLatlng.latitude, restLatlng.longitude, unit);                
              }
          } else if (this.$auth.setting && this.$auth.setting.delivery_charges_type == "Amount") {
              let checkValue = false;
              this.$auth.setting.charges.forEach(charge => {
                  if (charge.min_value < parseInt(this.getTotalItemAmount()) && parseInt(this.getTotalItemAmount()) <= charge.max_value) {
                      vardeliveryCharge = charge.delivery_charges;
                      checkValue = true;
                  }                
              });
              if(!checkValue){
                  vardeliveryCharge = this.$auth.setting.charges[0].delivery_charges;
                }
          }
        } else {
          vardeliveryCharge = 0;
        }        
        this.deliveryCharge = vardeliveryCharge;        
      },

      //Get Delivery Location Distance Find
      getDeliveryLocation(lat1, lon1, lat2, lon2, unit) {
      if (this.$auth.setting) {
        let dist = 0;
        let charges = this.$auth.setting.charges;
        if (lat1 != undefined && lon1 != undefined && lat2 != undefined && lon2 != undefined) {
          this.getOrderDistancekm({
            vendor_id: this.$auth.getVendorId(),
            useraddress_latitude: lat1,
            useraddress_longitude: lon1,
            store_latitude: lat2,
            store_longitude: lon2,
          }).then((data) => {
            if (data.code == 200) {
              let distancekm = data.Result.distance_km;
              let checkKM = false;
              charges.forEach((charge) => {
                if (charge.min_value < distancekm && distancekm <= charge.max_value) {
                  dist = charge.delivery_charges;
                  checkKM = true;
                }
              });
              if(!checkKM) {
                dist = this.$auth.setting.charges[0].delivery_charges;
              }              
              this.deliveryCharge = parseFloat(dist);
            } else {
              this.deliveryCharge = 0;
            }
          });
        } else {
          this.deliveryCharge = 0;
        }
      }
    },

      //Grand Total Amount Display
      getTotalAmount() {
          let gstTotalPrice = 0;
          if (this.$auth.setting && (this.$auth.setting.included_tax == 0 || this.$auth.setting.included_tax == 'Yes')) {
              gstTotalPrice = parseFloat(this.gstTotalAmount());
          }
          let totalItemPrice = parseFloat(this.getTotalItemAmount());
          //let deliveryTotalPrice = parseFloat(this.getDeliveryChrge());
          let deliveryTotalPrice = parseFloat(this.deliveryCharge);
          let grandTotal = (totalItemPrice + gstTotalPrice + deliveryTotalPrice - this.promoDiscount).toFixed(2);
          return grandTotal;
      },

      //Apply Promocode For function
      applyPromocode(code) {
          let currency = '';
          if (document.getElementById('currency') && document.getElementById('currency').childNodes[0]) {
              currency = document.getElementById('currency').childNodes[0].textContent;
          }

          this.promoCashback = '';
          this.promoDiscount = '';
          this.promocode = code;
          this.$refs.promocode.value = code;
          if (this.$refs.promocode.value) {
              this.promocodeRedeem({
                  user_id: this.userId,
                  vendor_id: this.$auth.getVendorId(),
                  is_langauge: this.$store.state.lang.locale,
                  original_price: this.getTotalItemAmount(),
                  coupon_code: this.$refs.promocode.value,
                  restaurant_id: this.restaurantData.restaurant_id,
              }).then((data) => {
                  if (data.code == 200) {
                      let result = data.Result;
                      if (result.minimum_order_amount <= this.getTotalItemAmount()) {
                          this.coupon_id = result.id;
                          if (result.promo_code_type == 2) {
                              //Falt Discount
                              let flatDiscount = result.flat_discount;
                              this.promoCodeType = 2;
                              if (flatDiscount > this.getTotalItemAmount()) {
                                  this.promoDiscount = this.getTotalItemAmount();
                              } else {
                                  this.promoDiscount = flatDiscount;
                              }
                              this.isPromocodeApply = true;
                              alert(this.$t("Coupon applied successfully. You have got ") + currency + this.promoDiscount + this.$t(" Discount"));
                          } else if (result.promo_code_type == 1) {
                              //Percenage Discount
                              this.promoCodeType = 1;
                              let discountAmount = ((this.getTotalItemAmount() / 100) * result.discount_percentage).toFixed(2);
                              this.promoDiscount = Math.min.apply(Math, [discountAmount, result.maximum_discount_amount]);
                              this.isPromocodeApply = true;
                              alert(this.$t("Coupon applied successfully. You have got ") + currency + this.promoDiscount + this.$t(" Discount"));
                          } else if (result.promo_code_type == 3) {
                              //Flat cashback
                              this.promoCodeType = 3;
                              let flatCashback = result.flat_discount;
                              if (flatCashback > this.getTotalItemAmount()) {
                                  this.promoCashback = this.getTotalItemAmount();
                              } else {
                                  this.promoCashback = flatCashback;
                              }
                              this.isPromocodeApply = true;
                              alert(this.$t("Coupon applied successfully. You have got ") + currency + this.promoCashback + this.$t(" cashback"));
                          } else if (result.promo_code_type == 4) {
                              //Percenage Discount
                              this.promoCodeType = 4;
                              let discountAmount = ((this.getTotalItemAmount() / 100) * result.discount_percentage).toFixed(2);
                              this.promoCashback = Math.min.apply(Math, [discountAmount, result.maximum_discount_amount]);
                              this.isPromocodeApply = true;
                              alert(this.$t("Coupon applied successfully. You have got ") + currency + this.promoCashback + this.$t(" cashback"));
                          }
                      } else {
                          alert(this.$t('Sorry, the minimum order amount is ') + currency + result.minimum_order_amount + ' for this coupon');
                      }

                  } else if (data.code == 101) {
                      this.promoError = data.msg;
                      setTimeout(() => this.promoError = false, 3000);
                  }
              });
          } else {
              this.promoError = this.$t("Please Enter Promocode");
              setTimeout(() => this.promoError = false, 3000);
          }
          $('#promocodeModal').modal('hide');
      },
      //Remove Apply Code
      removePromocode() {
          this.promocode = '';
          this.isPromocodeApply = false;
          this.promoDiscount = 0;
          this.promoCashback = 0;
      },

      processToPay() {
        if (this.isPickupDelivery == 1 || this.isPickupDelivery == -1) {
          if (this.shiping_id == '') {
            this.$toasted.error(this.$t("Please select or add address"), {
                duration: 2000
            });
            return false;
          }
        }
        this.is_btn_spinner = true;
        if (!this.isAsapEnable || !this.isRestaurantClosed || !this.isSameDayDelivery) {
            if (
                !this.scheduleDateTime.time ||
                this.scheduleDateTime.time == '' ||
                this.scheduleDateTime.time == 'asap'
            ) {
                this.$refs.DeliveryTime.openTime();
                this.is_btn_spinner = false;
                return false;
            }
        }
        if (this.$auth.user) {
            let itemDetailsArray = [];
            let itemDetails = {};
            
            this.cart.forEach(item => {
                let singleSelection = '';
                let multipleSelection = [];
                let multiple_selection = '';
                multiple_selection = item.selectCustoizeID.join();
                itemDetails = {
                    'item_id': item.item_id,
                    'amount': parseFloat(this.getTotalItemPrice(item)).toFixed(2),
                    'single_selection': singleSelection,
                    'is_bravges': '0',
                    'multiple_selection': multiple_selection,
                    'quantity': item.quantity,
                    'item_weight': item.item_weight,
                    'price_type': item.price_type,
                    'item_note': item.item_note
                }
                itemDetailsArray.push(itemDetails);
            });

            let future_delivery_date = '';
            if (this.scheduleDateTime) {
                if (this.scheduleDateTime.time && this.scheduleDateTime.time != 'asap') { //Schedule order
                    future_delivery_date = this.scheduleDateTime.date + ' ' + this.scheduleDateTime.time;
                }
            }
            let deliveryType = 'Delivery';
            if (this.isPickupDelivery == 1 || this.isPickupDelivery == -1) {
                deliveryType = 'Delivery';
            } else {
                deliveryType = 'Pickup';
            }
            this.place_order_parameter = {
                'address_id': this.shiping_id,
                'app_version_code': '',
                'braveges_amount': 0,
                'card_id': '',
                'cashback': this.promoCashback,
                'CGST': '0.0',
                'contact_less_delivery': this.contactDelivery,
                'coupon_id': this.coupon_id,
                'coupon_redeem': this.coupon_id == '' ? '' : this.promocode,
                'delivery_fee': this.deliveryCharge, //this.getDeliveryChrge(),
                'delivery_pickup_types': deliveryType,
                'delivery_type': this.isPickupDelivery,
                'device_type': 'Web',
                'discount_price': this.promoDiscount,
                'driver_tip': 0,
                'future_delivery_date': future_delivery_date,
                'is_langauge': this.$store.state.lang.locale,
                'notes': this.instructions,
                'original_price': this.getTotalItemAmount(), //this.getTotalAmount(),
                'payment_method': '',
                'payment_type': '',
                'remaining_amount': this.getTotalAmount(),
                'restaurant_id': this.restaurantData.restaurant_id,
                'restaurant_item_details': JSON.stringify(itemDetailsArray),
                'restaurant_service_fee': this.gstMaxtax,
                'service_fee': this.gstTotalAmount(),
                'SGST': 0.0,
                'sub_total': this.getTotalItemAmount(),
                'suggestion': '',
                'total_amount': this.getTotalAmount(),
                'transaction_id': '',
                'user_id': this.userId,
                'vendor_id': this.$auth.getVendorId(),
                'wallet_amount': 0.0,
                'orderImagesid': this.orderImagesId
            };
            this.checkRestaurantRadius();
            // this.is_btn_spinner= false;
            // this.$store.dispatch("order/placeOrderData",this.place_order_parameter);
            // this.$refs.redirectPayment.$el.click();
        } else {
            this.$router.push({
                name: "sign-in"
            });
        }
      },

      checkRestaurantRadius() {
          let vm = this;
          this.checkVendorRadius({
              is_langauge: this.$store.state.lang.locale,
              latitude: this.primaryAddress.latitude,
              longitude: this.primaryAddress.longitude,
              restaurant_id: this.restaurantId,
              vendor_id: this.$auth.getVendorId(),
          }).then((data) => {
              if (data.code == 200) {
                  this.is_btn_spinner = false;
                  this.$store.dispatch("order/placeOrderData", this.place_order_parameter);
                  this.$refs.redirectPayment.$el.click();
              } else {
                  alert(this.$t('Your location is out of reach'));
                  this.is_btn_spinner = false;
                  return false;
              }
          }).catch(function () {
              alert(vm.$t('Something went wrong, Please try again'));
              vm.is_btn_spinner = false;
              return false;
          });
      },

      goToHome() {
          this.$router.go(-1);
      },

      //Order Image Upload
      uploadImage(e) {
          let vm = this;
          var selectedFiles = e.target.files;
          var formData = new FormData();
          var totalImg = selectedFiles.length + this.images.length;
          if (totalImg > this.$auth.setting.order_image_upload_limit) {
              this.$toasted.error(this.$t('You have reached max image upload limit'), {
                  duration: 2000
              });
          } else {
              this.loading = true;
              this.is_btn_spinner = true;
              for (let i = 0; i < selectedFiles.length; i++) {
                  formData.append('image[' + i + ']', selectedFiles[i]);
              }
              this.postUploadOrderImages(formData).then((data) => {
                  if (data.code == 200) {
                      let imagesArray = data.imagedata;
                      if (localStorage.getItem('orderUploadImages') != '' || localStorage.getItem('orderUploadImages') != null) {
                          imagesArray.forEach(imgdata => {
                              this.images.push(imgdata);
                          });
                      } else {
                          this.images = imagesArray;
                      }
                      localStorage.setItem('orderUploadImages', JSON.stringify(this.images));
                      let ordImg = [];
                      this.images.forEach(imgid => {
                          ordImg.push(imgid.id)
                      });
                      this.orderImagesId = ordImg.join(',');
                      this.loading = false;
                      this.is_btn_spinner = false;
                  } else {
                      this.is_btn_spinner = false;
                      this.loading = false;
                  }
              })
          }
      },

      //Remove uplod image from
      removeUploadImage(id) {
          if (confirm(this.$t("Are you sure you want to delete?"))) {
              this.loading = true;
              this.is_btn_spinner = true;
              this.postremoveOrderImages({
                  imageid: id
              }).then((data) => {
                  if (data.code == 200) {
                      localStorage.setItem('orderUploadImages', '');
                      let ordImg = [];
                      this.orderImagesId = '';
                      var rIndex = this.images.findIndex(r => r.id == id);
                      this.images.splice(rIndex, 1);
                      this.images.forEach(imgid => {
                          ordImg.push(imgid.id)
                      });
                      localStorage.setItem('orderUploadImages', JSON.stringify(this.images));
                      this.orderImagesId = ordImg.join(',');
                      this.loading = false;
                      this.is_btn_spinner = false;
                  }
              })
          }
      },

      addItmeNote(itemIndex){
        this.modal_id = itemIndex;     
        var data = this.cart[itemIndex];
        this.itemNote = data.item_note;
        window.$("#itemNoteModal").modal("show");
      },

    addProductToCart(itemIndex){
        let itemdata = {
            'index':itemIndex,
            'note':this.itemNote
        }        
        this.$store.commit("product/setItemNote", itemdata);
        this.itemNote = '';
        window.$("#itemNoteModal").modal("hide");
    },

    changeKgValue(itemIndex){        
        this.item_index = itemIndex;
        var data = this.cart[itemIndex];
        this.itemWeight = data.item_weight;
        window.$("#itemWeightModal").modal("show");
        
    },

    changeProductToCart(itemIndex){
        let itemdata = {
            'index':itemIndex,
            'item_weight':this.itemWeight
        }
        if (this.itemWeight == 0) {
            this.$toasted.error(this.$t("Please enter proper weight"), {
                duration: 2000
            });
            return false;
        }
        this.$store.commit("product/setItemWeight", itemdata);
        this.itemWeight = '';
        window.$("#itemWeightModal").modal("hide");
    },

    floatingNumber(e) {
        const floating = /^[0-9.]\d*(\.\d{1,2})?$/;
        let char = String.fromCharCode(e.keyCode);
        if (floating.test(char)) return true;
        else e.preventDefault();
    },

    },
};
</script>

<style scoped>
.svprc span{
    color:#27ae60;
}
.delivery-address {
    padding: 15px;
}
button.btn.btn-primary.disabled,
.prcc-cls-blr,.prcc-cls,.prcc-cls:hover, .prcc-cls-blr:hover{
    background: #27AE60;
    border:none;
}

.btn--primary, .btn--primary:hover  {
  background: rgba(var(--theme-primary-button-color));
  color: #fff;
}

.cust_note img{
    height: 25px !important;
    width: 25px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media only screen and (max-width:767px) {
    .delivery-address p {
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
    }
    .item_note textarea{
        width: 100%;
    }
}
</style>
